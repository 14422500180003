import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { sign } from 'crypto';

@Injectable({
  providedIn: 'root'
})
export class NegateLoginGuard implements CanActivate {

    constructor(public authService: AuthService, public router: Router, public route: ActivatedRoute) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(async (resolve, reject) => {
			const params = next.queryParams;
    		const token = params['token'];
				if (token) {
					await this.authService.SignOut(true);
					resolve(true);
					return;
				} else {
					if  ( !this.authService.isLoggedIn() ) {
						resolve(true);
						return;
					} else {
							this.router.navigate(['/dashboard']);
							resolve(false);
							return;
					}
				}
			});
	}
  
}
