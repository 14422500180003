import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
import * as firebase from "firebase/compat/app";
import { OnboardingService } from './onboarding.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData: any = {mail: ''}; // Save logged in user data
  public user: any = {}
  public gastroID = "";
  constructor( // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public afs: AngularFirestore,
    public ngZone: NgZone,
    private storage: Storage,
    public menuCtrl: MenuController,
    public ar: ApplicationRef
  ) { 
    this.afAuth.onAuthStateChanged(u => {
        if (u) {
            this.menuCtrl.enable(true);
                this.user = u;
                localStorage.setItem('user', JSON.stringify(this.user));
                JSON.parse(localStorage.getItem('user'));
                this.gastroID = u.uid  
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        this.afs.doc(`Gastro-Profiles/${u.uid}`).get().subscribe((e) => {
          this.userData = e.data()
          this.ar.tick()
          //this.router.navigate(['dashboard']);
        })
      } else {
        this.userData = {mail: ''}; 
        this.user = {}
        this.gastroID = "";
        this.menuCtrl.enable(false);
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  SignIn(email, password) {
  return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(result => {

              this.afs
                  .doc(`Gastro-Profiles/${result.user.uid}`)
                  .get()
                  .subscribe(e => {
                      if (e.exists) {
                          this.gastroID = result.user.uid
                          this.storage.set('userID', result.user.uid).then((e) => {
                              this.SetUserData(result.user);
                          })
                     
                      } else {
                          window.alert(
                              'Dieser Email ist bereits als Gast registriert und kann sich nicht als Gastronom anmelden'
                          );
                      }
                  });
          
      })
      .catch(error => {
          window.alert(error.message);
      });
}

SetUserData(user, login = true) {
        this.afs.collection('Gastro-Profiles').doc(user.uid).get().subscribe((doc) => {
            if (doc.data()['profileState'] == 2) {
                this.afs.collection('Gastro-Profiles').doc(user.uid)
                .snapshotChanges().subscribe((e) => {
                    this.userData = e.payload.data()
                    this.router.navigate(['dashboard']);
                })
            } else if (doc.data()['profileState'] == 0) {
                this.afs.collection('Gastro-Profiles').doc(user.uid)
                .snapshotChanges().subscribe((e) => {
                    this.userData = e.payload.data()
                    this.router.navigate(['onboarding']);
                })
            } else {
                this.afs.collection('Gastro-Profiles').doc(user.uid)
                .snapshotChanges().subscribe((e) => {
                    this.userData = e.payload.data()
                    this.router.navigate(['welcome']);
                })
            }
        })
  }

// Sign out
async SignOut(noRedirect = false) {
    await this.afAuth.signOut()
	this.user = {}
	this.userData = {}
	localStorage.removeItem('user');
	if (!noRedirect) {
		this.router.navigate(['login']);
	}
}

isLoggedIn(): boolean {
    if (localStorage.getItem('user') != undefined) {
 const user = JSON.parse(localStorage.getItem('user'));
 return (user !== null) ? true : false;
    } else {
        return false
    }
}

initialSetup(user) {
    this.afs.doc(`Gastro-Profiles/${user.uid}`).set({
        // color: '#000000',
        id: user.uid,
        mail: user.email,
        profileState: 0,
        createdAt: firebase.default.firestore.FieldValue.serverTimestamp()
        // name: user.name,
        // offers: false,
        // categorys: [],
        // orderCount: 0,
        // orderVolumen: 0,
        // paymentOptions: {
        //     name: 'Bar',
        //     option: 'bar'
        // },
       
        // city: '',
        // description: '',
        // plz: '',
        // self: false,
        // street: ''
    }).then(async () => {
        this.SetUserData(user)
        await this.setupGastro()
    })
}

async setupGastro () {
    let gastroProfile = await this.loadProfile(); //loading the gastroProfile
    await this.initialSetupGastroDoc(gastroProfile); //creating the initial 'gastro' Object in firestore
}


async loadProfile() {//loads Gastro Profile Data
    let gastroProfile = await this.afs
      .collection("Gastro-Profiles")
      .doc(this.gastroID)
      .get()
      .toPromise();
    return gastroProfile.data();
  }

  async initialSetupGastroDoc(user) {//creates 'gastro' entry in firestore
        this.afs.collection('gastro').doc(user.id).set({
          active: true,
          address: {},
          //categorys: [],
          color: "#000000",
          deliveryOptions: {
            fee: 0,
            freeDeliveryAmount: 0,
            maxDistance: 0,
            minOrderAmount: 0,
            deliveryDistance: [],
          },
          deliveryPreTime: 0,
          deliveryTimes: [
            { day: 0, from: "00:00", to: "23:59" },
            { day: 1, from: "00:00", to: "23:59" },
            { day: 2, from: "00:00", to: "23:59" },
            { day: 3, from: "00:00", to: "23:59" },
            { day: 4, from: "00:00", to: "23:59" },
            { day: 5, from: "00:00", to: "23:59" },
            { day: 6, from: "00:00", to: "23:59" },
          ],
          discount: [],
          driver: [],
          fee: 0,
          feeName: "",
          groupOrder: true,
          hasDelivery: false,
          hasFee: false,
          hasPickup: false,
          hasToGo: true,
          headerImgName: "",
          hotel: false,
          customerNr: await this.createCustId().then((res) => {
            return res;
          }),
          id: user.id,
          invoiceCounter: 0,
          invoicePre: "",
          lat: 0.0,
          logoImage: "",
          long: 0.0,
          mail: user.mail,
          maxDelivery: "00:00",
          maxPickup: "00:00",
          menuImage: "",
          minDelivery: "00:00",
          minPickup: "00:00",
          name: '',
          newStyle: true,
          offers: false,
          openingHours: [
            { day: 0, from: "00:00", to: "23:59" },
            { day: 1, from: "00:00", to: "23:59" },
            { day: 2, from: "00:00", to: "23:59" },
            { day: 3, from: "00:00", to: "23:59" },
            { day: 4, from: "00:00", to: "23:59" },
            { day: 5, from: "00:00", to: "23:59" },
            { day: 6, from: "00:00", to: "23:59" },
          ],
          orderCount: 0,
          orderVolumen: 0,
          ownNicknames: true,

          paymentOptions: [],

          pickupPreTime: 0,
          pickupTimes: [
            { day: 0, from: "00:00", to: "23:59" },
            { day: 1, from: "00:00", to: "23:59" },
            { day: 2, from: "00:00", to: "23:59" },
            { day: 3, from: "00:00", to: "23:59" },
            { day: 4, from: "00:00", to: "23:59" },
            { day: 5, from: "00:00", to: "23:59" },
            { day: 6, from: "00:00", to: "23:59" },
          ],
          pref: { vegetarian: false, vegan: false },
          random: false,
          report: true,
          reportIntervall: [false, true, false, false],
          self: true,
          showReports: true,
          sms: false,
          steuerNr: "",
          stripeAccID: "",
          stripeFee: {
            fixed: 25,
            percentual: 0.017,
          },
          whereOptions: [
            {
              name: "In-House",
              option: "inhouse",
              togo: false,
              delivery: false,
              pickup: false,
            },
            {
              name: "PICKUP",
              option: "pickup",
              togo: true,
              delivery: false,
              pickup: true,
            },
            {
              name: "DELIVERY",
              option: "lieferung",
              togo: true,
              delivery: true,
              pickup: false,
            },
          ],
          city: "",
          description: "",
          plz: "",
          street: "",
        });
        let docRef = await this.afs.doc(`gastro/${user.id}`)
        await docRef.collection("products").add({
          dishes: [],
        });
        await docRef.collection('categories').add({
          categories: [],
        })
        await this.afs
          .collection("accountManagement")
          .doc(user.id)
          .set({
            gastros: [user.id],
            rootUsers: [user.id],
          });
  }

  public async createCustId() { //creates a new 'globals' entry in firestore
    const db = firebase.default.firestore();

    const increment = firebase.default.firestore.FieldValue.increment(1);
    const docRef = db.collection("globals").doc(environment.globalID); 
    await docRef.update({ countID: increment });//increments the gastro Count
    return (await docRef.get()).data().countID;
  }

// Sign up with email/password
SignUp(email, password) {
    return this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then(result => {
            /* Call the SendVerificaitonMail() function when new user sign 
    up and returns promise */
            //this.SendVerificationMail();
            //(<any>result.user).name = name;
            //(<any>result.user).kind = kind;
            this.initialSetup(result.user);
        })
        .catch(error => {
            window.alert(error.message);
        });
}


async confirmPassword(pw: string) {
  return await this.afs.collection('gastro').doc(this.gastroID).get().toPromise().then(gastro => {
      return this.afAuth.signInWithEmailAndPassword(gastro.data()['mail'], pw).then(
          () => {
              return true;
          },
          (err) => {
              console.log(err);
              return false;
          }
      )
  })
}

public async signInWithToken(token: string) {
	await this.SignOut();
	return this.afAuth.signInWithCustomToken(token).then((result) => {
		this.afs
		.doc(`Gastro-Profiles/${result.user.uid}`)
		.get()
		.subscribe(e => {
			if (e.exists) {
				this.gastroID = result.user.uid
				this.storage.set('userID', result.user.uid).then((e) => {
					this.SetUserData(result.user);
				})
			
			} else {
				window.alert(
					'Dieser Email ist bereits als Gast registriert und kann sich nicht als Gastronom anmelden'
				);
			}
		});
	})
  }
}
