import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Platform, MenuController } from "@ionic/angular";
import { AuthService } from "./services/auth.service";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import { AccountService } from "./services/account.service";
import { DocumentData, QueryDocumentSnapshot } from "@angular/fire/compat/firestore";
import { UpdatesService } from "./services/update.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [

  	{
  		title: "Dashboard",
  		url: "/dashboard",
  		icon: "trending-up-outline",
  		visible: () => {
  			return true;
  		},
  	},
  	{
  		title: "Mein Laden",
  		url: "/gastro-profil",
  		icon: "person-outline",
  		visible: () => {
  			return true;
  		},
  	},
  	{
  		title: "Speisekarte",
  		url: "/visualbuilder",
  		icon: "fast-food-outline",
  		visible: () => {
		  return true;
  		},
	  },
  	{
  		title: "Speisekarte (Expertenmodus)",
  		url: "/menu",
  		icon: "construct-outline",
  		visible: () => {
  			return true;
  		},
  	},
  	{
  		title: "Speisekartendesign",
  		url: "/digital-menu",
  		icon: "grid-outline",
  		visible: () => {
  			const gastro = this.accountService.gastros[0];
  			if (gastro === false || gastro === undefined) {
  				return false;
  			}
  			const hasTemplateSystem = gastro.data.hasTemplateSystem;
  			if (hasTemplateSystem === true) {
  				return true;
  			}
  			return false;
  		},
  	},
  	{
  		title: "Bestellübersicht",
  		url: "/order-tracker",
  		icon: "clipboard-outline",
  		visible: () => {
  			return true;
  		},
  	},
    {
      title: 'Gutscheine',
      url: '/coupons',
      icon: 'cash-outline',
      visible: () => {
        const gastro = this.accountService.gastros[0];
        if (gastro === false || gastro === undefined) {
          return false;
        }
        const hasCustomerCoupon = gastro.data.hasCustomerCoupon;
        if (hasCustomerCoupon === true) {
          return true;
        }
        return false;
      }
    },
    {
      title: 'Kundenkarte',
      url: '/loyalty',
      icon: 'gift-outline',
      visible: () => {
        const gastro = this.accountService.gastros[0];
        if (gastro === false || gastro === undefined) {
          return false;
        }
        const hasCustomerLoyality = gastro.data.hasCustomerLoyality;
        if (hasCustomerLoyality === true) {
          return true;
        }
        return false;
      }
    },
    {
      title: 'Einstellungen',
      icon: 'cog-outline',
      visible: () => {
       return true
      },
      children: [{
        title: 'Automatische Abbuchung', url: '/subscribe', icon: 'card-outline',
        visible: () => {
          const gastroProfile = this.accountService.gastroProfile;
          if (gastroProfile === false || gastroProfile === undefined) {
            return false;
        }
          const autoBillingEnabled = (gastroProfile as QueryDocumentSnapshot<DocumentData>).data().autoBillingEnabled;
          if (autoBillingEnabled === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      { title: 'Bestellannahme & Bondruck',visible: () => true, url: '/printer', icon: 'print-outline' },
      { title: 'APIs', url: '/apis', icon: 'magnet-outline',
      visible: () => {
        const gastroProfile =this.accountService.gastroProfile;
        if (gastroProfile === false || gastroProfile === undefined) {
            return false;
        }
        const canSeeSettings = (gastroProfile as QueryDocumentSnapshot<DocumentData>).data().canSeeSettings;
        if (canSeeSettings === true) {
            return true;
        }
        return false;
    },},
    {
      title: 'orderbird', url: '/orderbird', icon: 'assets/img/orderbirdLogo-blue.svg',
      visible: () => {
        const orderbirdAPIref = this.accountService.checkoutSysAPIs.find(api => api.id==="orderbird")
        //Check if the orderbird API is available on this Venue
        if(orderbirdAPIref){
          if(orderbirdAPIref.data.active==true){
            return true;
          }
        }
        return false;
      }
    }]
    },
    {
      title: 'Tische',
      url: '/table',
      icon: 'restaurant-outline',
      visible: () => {
        return true;
      }
    },
    {
      title: 'QR Display',
      url: '/qr-display',
      icon: 'qr-code-outline',
      visible: () => {
        return true;
      }
    },
	{
		title: 'Kundendaten', 
		url: '/analytics', 
		icon: 'analytics-outline', 
		visible: () => {
			const gastroProfile = this.accountService.gastroProfile;
			if (gastroProfile === false || gastroProfile === undefined) {
			  return false;
		  	}
			const autoBillingEnabled = (gastroProfile as QueryDocumentSnapshot<DocumentData>).data().hasAnalytics;
			if (autoBillingEnabled === true) {
			  return true;
			} else {
			  return false;
			}
		}
	  },
    {
      title: 'Webseite',
      url: '/website-data',
      icon: 'laptop-outline',
      visible: () => {
        for (let i = 0; i < this.accountService.gastros.length; i++) {
          if (this.accountService.gastros[i].data.hasWebsite == true)
            return true;
        }
        return false;
      }
    },
    {
      title: 'Hilfe',
      url: 'https://support.orderbird.com/de_DE/bestellmanagement',
      absolut: true,
      icon: 'help-buoy-outline',
      visible: () => {
        return true;
      }
    },
  ];

  constructor(
    private platform: Platform,
    private menu: MenuController,
    public authService: AuthService,
    public accountService: AccountService,
    public updatesService: UpdatesService,
    private router: Router,
  ) {
  }

  ngOnInit() {
  	registerLocaleData(localeDe, "de");
  	const path = window.location.pathname.split("folder/")[1];
  	if (path !== undefined) {
  		this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
  	}
  }

  closeMenu() {
  	this.menu.close();
  }

  navigate(link) {
  	this.router.navigate([link]);
  	this.closeMenu();
  }

  routeMe(link, absolut) {
  	if (absolut !== true) {
  		this.router.navigate([link]);
  	} else {
  		window.open(link, "_blank").focus();
  	}
  }
  isSelected(item) {
  	return item == this.router.url ? true : false;
  }

}
