import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginGuard } from "./guards/login.guard";
import { NegateLoginGuard } from "./guards/negate-login.guard";
import { NegateUserStateGuard } from "./guards/negate-user-state.guard";
import { UnsafedChangesGuard } from "./guards/unsafed-changes.guard";
import { UserStateGuard } from "./guards/user-state.guard";
import { WelcomeGuard } from "./guards/welcome.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [NegateLoginGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    canActivate: [LoginGuard, NegateUserStateGuard],
    canDeactivate: [UnsafedChangesGuard]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule),
    canActivate: [LoginGuard,UserStateGuard]
  },
  {
    path: 'order-tracker',
    loadChildren: () => import('./pages/order-tracker/order-tracker.module').then( m => m.OrderTrackerPageModule)
  },
  {
    path: 'order-modal',
    loadChildren: () => import('./pages/order-tracker/components/order-modal/order-modal.module').then( m => m.OrderModalPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [NegateUserStateGuard, LoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [NegateLoginGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule),
    canActivate: [LoginGuard, WelcomeGuard]
  },
  {
    path: 'website-data',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/website-data/website-data.module').then( m => m.WebsiteDataPageModule)
  },
  {
    path: 'digital-menu',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/digital-menu/digital-menu.module').then( m => m.DigitalMenuPageModule)
  },
  {
    path: 'visualbuilder',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/visualbuilder/visualbuilder.module').then( m => m.VisualbuilderPageModule),
  },
  {
    path: 'dish-detail',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/dish-detail/dish-detail.module').then( m => m.DishDetailPageModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule),
    canActivate: [LoginGuard, NegateUserStateGuard],
  },
  {
    path: 'loyalty',
    loadChildren: () => import('./pages/loyalty/loyalty.module').then( m => m.LoyaltyPageModule),
    canActivate: [LoginGuard, NegateUserStateGuard],
  },
  {
    path: 'coupon-generate-modal',
    loadChildren: () => import('./pages/coupon-generate-modal/coupon-generate-modal.module').then( m => m.CouponGenerateModalPageModule),
    canActivate: [LoginGuard, NegateUserStateGuard]
  },
  {
    path: 'popover-tooltip',
    loadChildren: () => import('./pages/popover-tooltip/popover-tooltip.module').then( m => m.PopoverTooltipPageModule),
    canActivate: [LoginGuard, NegateUserStateGuard]
  },
  {
    path: 'gastro-profil',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/gastro-profil/gastro-profil.module').then( m => m.GastroProfilPageModule),
  },
  {
    path: 'subscribe',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/subscribe/subscribe.module').then( m => m.SubscribePageModule)
  },
  {
    path: 'table',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/table/table.module').then( m => m.TablePageModule)
  },
  {
	canActivate: [LoginGuard, NegateUserStateGuard],
    path: 'table-modal-change-logo',
    loadChildren: () => import('./pages/table-modal-change-logo/table-modal-change-logo.module').then( m => m.TableModalChangeLogoPageModule)
  },
  {
    path: 'printer',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/printer/printer.module').then( m => m.PrinterPageModule)
  },
  {
    path: 'apis',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/apis/apis.module').then( m => m.ApisPageModule)
  },
  {
    path: 'verify-stripe',
    loadChildren: () => import('./pages/verify-stripe/verify-stripe.module').then( m => m.VerifyStripePageModule)
  },
  {
    path: 'contract/:id',
    loadChildren: () => import('./pages/contract/contract.module').then( m => m.ContractPageModule)
  },
  {
	canActivate: [LoginGuard, NegateUserStateGuard],
    path: 'orderbird',
    loadChildren: () => import('./pages/orderbird/orderbird.module').then( m => m.OrderbirdPageModule)
  },
  {
	canActivate: [LoginGuard, NegateUserStateGuard],
    path: 'orderbird/success',
    loadChildren: () => import('./pages/orderbird/orderbird.module').then( m => m.OrderbirdPageModule)
  },
  {
	canActivate: [LoginGuard, NegateUserStateGuard],
    path: 'qr-display',
    loadChildren: () => import('./pages/qr-display/qr-display.module').then( m => m.QrDisplayPageModule)
  },
  {
    path: 'analytics',
    canActivate: [LoginGuard, NegateUserStateGuard],
    loadChildren: () => import('./pages/analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'stripe-onboarding-link/:id',
    loadChildren: () => import('./pages/stripe-onboarding-link/stripe-onboarding-link.module').then( m => m.StripeOnboardingLinkPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
	canActivate: [NegateLoginGuard],
  }







];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
