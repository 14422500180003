import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-popover-tooltip',
  templateUrl: './popover-tooltip.page.html',
  styleUrls: ['./popover-tooltip.page.scss'],
})
export class PopoverTooltipPage implements OnInit {
  constructor() { }
  code
  standalone=""
  header=""
  header2=""
  header3=""
  text =""
  text2 =""
  text3 =""
  ngOnInit() {
    this.translateCode(this.code)
  }
  public translateCode(code){
    switch (code) {
      case 'combi':
        this.header="Kombinierbar"
        this.text= "Kombinierbare Gutscheine können mit anderen Gutscheinen kombiniert werden."
        break;
        case 'custom-code':
          this.header="Eigener Code"
          this.text= "Gutschein Code muss 6 bis 10 Zeichen lang sein und aus Großbuchstaben oder Zahlen bestehen (keine Sonderzeichen)"
          break;
        case 'karten-art':
          this.header="Splitometer"
          this.header2="Punkte-Karte"
          this.text= "Rabatt wird bei jeder Bestellung erhöht, bis zu einem Limit. Rabatt wird bei Anwendung nicht rurückgesetzt, sondern am Anfang des Monats."
          this.text2= "Die standard Treue-Punkte-Karte. Der Kunde bekommt bei jeder Bestellung einen Punkt und bei voller Karte wird ein Gutschein automatisch erstellt."
          break;
        case 'punkte-ziel':
          this.header="Punkteziel"
          this.text= "Das Punkteziel definiert, wie viele Punkte der Gast sammeln muss, um einen Gutschein zu erhalten."
          break;
        case 'rhythmus':
          this.header="Rhythmus der Erhöhungen"
          this.text= "Hier wird definiert, wie viele Bestellungen ein Gast benötigt um den Rabatt zu erhöhen."
          break;
        case 'block':
          this.header="blockierend"
          this.header2= "leicht blockierend"
          this.header3= "nicht blockierend"
          this.text= "Der Gast kann, nachdem das Ziel erreicht wurde, keine weteren Punkte sammeln, bis der Gutschein verwendet wurde."
          this.text2 = "Der Gast kann, nachdem das Ziel erreicht wurde, weiter Punkte sammeln. Ein neuer Gutschein wird erst ausgegeben, wenn der bestehende Gutschein verwendet wurde."
          this.text3 = "Der Gast kann, nachdem das Ziel erreicht wurde, weiter Punkte sammeln. Ein neuer Gutschein wird erstellt, sobald das Ziel wieder erreicht wurde."
          break;
        case 'instaURL':
          this.header="Ihr Instagram"
          this.header2="URL"
          this.text= "Verknüpfen Sie Ihre Instagram Seite! Fügen Sie dazu einfach die URL zu Ihrer Seite hier ein."
          this.text2= "Die URL muss mit https://www.instagram.com beginnen, um akzeptiert zu werden. Testen Sie Ihren Link mit einem Klick auf das Icon in der Vorschau!"
          break;
        case 'facebookURL':
          this.header="Ihr Facebook"
          this.header2="URL"
          this.text= "Verknüpfen Sie Ihre Facebook Seite! Fügen Sie dazu einfach die URL zu Ihrer Seite hier ein."
          this.text2= "Die URL muss mit https://www.facebook.com beginnen, um akzeptiert zu werden. Testen Sie Ihren Link mit einem Klick auf das Icon in der Vorschau!"
          break;
        case 'edit':
          this.standalone="Bearbeiten"
          break;
        case 'move':
          this.standalone="Verschieben"
          break;
        case 'delete':
          this.standalone="Löschen"
          break;
        case 'outerhouse':
          this.standalone="Außer-House"
          break;
        case 'inhouse':
          this.standalone="Inhouse"
          break;
          case 'new-cat':
            this.header="Neue Kategorie"
            this.text= "Beim Anlegen der Kategorie kannst du im ersten Schritt nur den Namen, die Beschreibung und die Art auswählen. Nachdem du die Eingaben gespeichert hast, kannst du nun die neu erstellte Kategorie bearbeiten und weitere Einstellungen vornehmen (z.B.: Altersabfrage, automatisches Ein- und Ausblenden oder Kategoriebilder hinzufügen)"
            
           
          break;
          case 'new-dish':
            this.header="Neues Produkt"
            this.text= "Erstelle hier deine Artikel und definiere Name/Titel, Beschreibung und Preis. Optional kannst du auch ein Produktbild hochladen oder Tags hinzufügen. Um Extras und Optionen hinzuzufügen, speichere den Artikel einmal ab und klicke anschließend wieder oben links auf das Bearbeitenfeld. Jetzt kannst du Extras und Optionen hinzufügen.)"
            
           
          break;
          case 'printer-setting':
            this.header="Drucker Einstellung"
            this.text= "Willst du Speisen und Getränke auf getrennte Bons, allerdings über einen Drucker ausdrucken? Kein Problem! Aktiviere “Essen und Trinken an unterschiedlichen Druckern ausdrucken”. Nun gibst du in der Split-Waiter App unter Einstellungen die IP-Adresse deines Druckers zweimal ein (einmal beim Getränke- und einmal beim Essensdrucker)."
            break;
            case 'table-logo':
              this.header="Logo verändern"
              this.text= "Hier kannst du sowohl aus den angezeigten Möglichkeiten wählen als auch dein eigenes Logo hochladen, um es innerhalb deiner QR-Codes zu platzieren. Achte darauf, dass die Logo-Datei einen weißen Hintergrund hat, sodass der QR-Code in Folge auch ausgelesen werden kann."
              break;
              case 'table-delivery':
              this.header="Liefertisch"
              this.text= "Wähle hier die Tischnummer aus, die du als deinen Liefertisch ausweisen möchtest. Der ihr zugewiesene QR-Code kann nun für Outerhouse Bestellungen verwendet werden"
              break;
              case 'display':
              this.header="Textfarbe"
              this.text= "Hier kannst du die Farbe wählen, in der die Tischnummer auf deinem Aufsteller dargestellt wird. Achte darauf, dass diese sich von ihrem Hintergrund abhebt. Die Tischnummer wird unten rechts im Eck platziert."
              break;
              case 'live-demo':
              this.header="Livevorschau"
              this.text= "Klicke auf den jeweiligen Button, um deine Menükarte aus Kundensicht zu sehen. Achtung: Achte darauf, dass du vorher unter “Table” mindestens einen Inhouse- und/oder Outerhouse-Tisch angelegt hast."
              break;
              case 'special-time':
              this.header="Feiertage/Sonderzeiten"
              this.text= "Füge vorab Feiertage oder Kurzurlaube hinzu. Auch wenn du deinen Betrieb an bestimmten Tagen abweichend zu deinen normalen Öffnungszeiten öffnest/schließt, kannst du diese Zeiten hier hinterlegen."
              break;
              case 'language':
              this.header="Sprache"
              this.text= "“Füge hier zusätzliche Sprachen für die Menükarte hinzu. Die Übersetzungen in die neue Sprache kannst du im Menüpunkt Speisekarte dann selbst hinzufügen. Klicke hierzu auf die Weltkugel (rechts oben) und wähle die neue Sprache aus. Du kannst nun die Produkte, Beschreibungen und Optionen in die gewünschte Sprache übersetzen."
              break;
        
      default:
        break;
    }
  }
}
