import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { Category, Extra, Product } from '../shared/split-submodules/types/types';
import { UtilService } from './util.service';


//#region Interfaces

export interface MenuTranslation {
  baseDocId: string,
  products: any,
  productDocId: string,
  categories: any,
  categoryDocId: string,
  extras: any,
  extrasDocId: string,
}

export interface CategoryTranslation {
  name: string,
  description: string
}

export interface ProductTranslation {
  name: string,
  description: string
}

export interface ExtraTranslation {
  extraCategories: ExtraCategoryTranslation[],
  description: string,
}

export interface ExtraCategoryTranslation {
  description: string,
  name: string,
  extraItems: ExtraItemTranslation[],
}

export interface ExtraItemTranslation {
  name: string,
}

//#endregion

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private languageSubscription: Subscription;
  private productSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();
  private categorySubscriptions: Map<string, Subscription> = new Map<string, Subscription>();
  private extraSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();

  private menuTranslations: Map<string, MenuTranslation> = new Map<string, MenuTranslation>();

  private currentGastroId;

  constructor(
    private afs: AngularFirestore,
    private afa: AngularFireAuth,
    private utilService: UtilService,
  ) { 
    afa.onAuthStateChanged(u => {
		if (u) {
			this.loadMenuOfGastro(u.uid);
		}
    })
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  /**
   * Returns all languageCodes that are currently listed
   * @returns 
   */
  public getCurrentLanguages(): string[] {
    return Array.from(this.menuTranslations.keys());
  }

  /**
   * Returns the translation of a menu
   * @param languageCode the language of the menu
   * @returns 
   */
  public getMenuLanguage(languageCode: string): MenuTranslation {
    return this.menuTranslations.get(languageCode);
  }

  /**
   * Clears all current languageSubscriptions
   */
  private clearSubscriptions() {
    if (this.languageSubscription !== undefined) {
      this.languageSubscription.unsubscribe();
    }
    for (const subscription of this.productSubscriptions.values()) {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    }
    for (const subscription of this.categorySubscriptions.values()) {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    }
    for (const subscription of this.extraSubscriptions.values()) {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    }
  }

  /**
   * Loads the translations for a gastro
   * @param gastroId 
   */
  public loadMenuOfGastro(gastroId: string): void {

    this.clearSubscriptions();

    this.currentGastroId = gastroId;
    
    const gastroRef = this.afs.collection('gastro').doc(gastroId);
    this.languageSubscription = gastroRef.collection('languages').snapshotChanges().subscribe(languageCol => {
      this.menuTranslations.clear();
      for (const languageDoc of languageCol) {
        const data = languageDoc.payload.doc.data();
        const languageCode: string = data.languageCode;

        if (this.menuTranslations.get(languageCode) !== undefined) {
          continue;
        } 

        this.menuTranslations.set(languageCode, {
          baseDocId: languageDoc.payload.doc.id,
          products: {},
          productDocId: "",
          categories: {},
          categoryDocId: "",
          extras: {},
          extrasDocId: "",
        });
        this.productSubscriptions.set(
          languageCode, 
          gastroRef.collection('languages').doc(languageDoc.payload.doc.id).collection('products').snapshotChanges().subscribe(productTranslationsCol => {
            let products = {};
            const menuTranslation = this.menuTranslations.get(languageCode);

            for (const productTranslationDoc of productTranslationsCol) {
              menuTranslation.productDocId = productTranslationDoc.payload.doc.id;
              // this.currentProductDocId = productTranslationDoc.id;
              const productTranslations = productTranslationDoc.payload.doc.data().products;
              Object.assign(products, productTranslations);
            }

            menuTranslation.products = products;
          })
        );

        this.categorySubscriptions.set(
          languageCode, 
          gastroRef.collection('languages').doc(languageDoc.payload.doc.id).collection('categories').snapshotChanges().subscribe(categoryTranslationsCol => {
            let categories = {};
            const menuTranslation = this.menuTranslations.get(languageCode);

            for (const categoryTranslationDoc of categoryTranslationsCol) {
              menuTranslation.categoryDocId = categoryTranslationDoc.payload.doc.id
              // this.currentCategoryDocId = categoryTranslationDoc.id;
              const categoryTranslations = categoryTranslationDoc.payload.doc.data().categories;
              Object.assign(categories, categoryTranslations);
            }

            menuTranslation.categories = categories;
          })
        );

        this.extraSubscriptions.set(
          languageCode,
          gastroRef.collection('languages').doc(languageDoc.payload.doc.id).collection('extras').snapshotChanges().subscribe(extraTranslationsCol => {
            let extras = {};
            const menuTranslation = this.menuTranslations.get(languageCode);

            for (const extraTranslationsDoc of extraTranslationsCol) {
              menuTranslation.extrasDocId = extraTranslationsDoc.payload.doc.id;
              const extraTranslations = extraTranslationsDoc.payload.doc.data().extras;
              Object.assign(extras, extraTranslations);
            }

            menuTranslation.extras = extras;
          })
        )
      }
    });
  }

  /**
   * Updates the entry in the database. All new values will have empty values
   * @param gastroId the gastro in which the entry should be created
   * @param languageCode the language in which the entry should be in
   * @param products all products
   * @param categories all categories
   */
  public async createMenuLanguageFiles(gastroId: string, languageCode: string) {
    const gastroRef = this.afs.collection('gastro').doc(gastroId);
    const gastroTranslation = await gastroRef.collection('languages', ref => ref.where("languageCode", "==", languageCode).limit(1)).get().toPromise();

    let gastroTranslationDoc;
    
    //No languageElement exists for this language
    if (gastroTranslation.empty) {
      gastroTranslationDoc = await gastroRef.collection('languages').add({
        languageCode: languageCode,
        description: "",
      });
    }
    //A languageElement already exists for this language
    else {
      gastroTranslationDoc = gastroTranslation.docs[0];
    }

    const languageRef = gastroRef.collection('languages').doc(gastroTranslationDoc.id);

    const products = [];
    const categories = [];
    const productCol = await gastroRef.collection('products').get().toPromise();
    for (const productDoc of productCol.docs) {
      const data = productDoc.data();
      for (const product of data.dishes) {
        products.push(product);
      }

      if (data.categories !== undefined) {
        for (const category of data.categories) {
          categories.push(category);
        } 
      }
    }

    if (categories.length === 0) {
      const categoryCol = await gastroRef.collection('categories').get().toPromise();
      for (const categoryDoc of categoryCol.docs) {
        const data = categoryDoc.data();
        
        for (const category of data.categories) {
          categories.push(category);
        } 
      } 
    }

    const extras = [];
    const extrasCol = await gastroRef.collection('dishExtras').get().toPromise();
    for (const extraDoc of extrasCol.docs) {
      const data = extraDoc.data();
	  //self healing if extra.id === undefined -> take doc.id
	  if (data.id === undefined || data.id === "") {
		data.id = extraDoc.id;
	  }
      extras.push(data);
    }

    this.createProductTranslations(languageRef, products);
    this.createCategoryTranslations(languageRef, categories);
    this.createExtraTranslations(languageRef, extras)
  }

  /**
   * Updates the entry in the database. All new values will have empty values
   * @param languageRef the reference to the base language Document
   * @param products all products
   */
  private async createProductTranslations(
    languageRef: AngularFirestoreDocument<firebase.default.firestore.DocumentData>, 
    products: any[]
  ) {
    const productTranslationsCollection = await languageRef.collection('products').get().toPromise()

    let existingProductTranslations = {};
    for (const productTranslationsDoc of productTranslationsCollection.docs) {
      const data = productTranslationsDoc.data();
      existingProductTranslations = Object.assign(existingProductTranslations, data.products);      
    }

    let newProductTranslations = {};
    for (const product of products) {
      newProductTranslations[product.id] = {
        name: "",
        description: "",
      }
    }

    for (const key of Object.keys(newProductTranslations)) {
      if (existingProductTranslations[key] !== undefined) {
        newProductTranslations[key] = existingProductTranslations[key];
      }
    }

    if (productTranslationsCollection.empty) {
      languageRef.collection('products').add({
        products: newProductTranslations,
      })
    } else {
      languageRef.collection('products').doc(productTranslationsCollection.docs[0].id).update({
        products: newProductTranslations,
      })
    }
  }

  /**
   * Updates the entry in the database. All new values will have empty values
   * @param languageRef the reference to the base language Document
   * @param categories all categories
   */
  private async createCategoryTranslations(
    languageRef: AngularFirestoreDocument<firebase.default.firestore.DocumentData>, 
    categories: any[]
  ) {
    const categoryTranslationsCollection = await languageRef.collection('categories').get().toPromise()

    let existingCategoryTranslations = {};
    for (const categoryTranslationsDoc of categoryTranslationsCollection.docs) {
      const data = categoryTranslationsDoc.data();
      existingCategoryTranslations = Object.assign(existingCategoryTranslations, data.categories);      
    }

    let newCategoryTranslations = {};
    for (const category of categories) {
      newCategoryTranslations[category.id] = {
        name: "",
        description: "",
      }
    }

    for (const key of Object.keys(newCategoryTranslations)) {
      if (existingCategoryTranslations[key] !== undefined) {
        newCategoryTranslations[key] = existingCategoryTranslations[key];
      }
    }

    if (categoryTranslationsCollection.empty) {
      languageRef.collection('categories').add({
        categories: newCategoryTranslations,
      })
    } else {
      languageRef.collection('categories').doc(categoryTranslationsCollection.docs[0].id).update({
        categories: newCategoryTranslations,
      })
    }
  }

  /**
   * Updates the entry in the database. All new values will have empty values
   * @param languageRef the reference to the base language Document
   * @param extras all extras
   */
  private async createExtraTranslations(
    languageRef: AngularFirestoreDocument<firebase.default.firestore.DocumentData>, 
    extras: any[],
  ) {
    const extraTranslationsCollection = await languageRef.collection('extras').get().toPromise()

    let existingExtraTranslations = {};
    for (const extraTranslationsDoc of extraTranslationsCollection.docs) {
      const data = extraTranslationsDoc.data();
      existingExtraTranslations = Object.assign(existingExtraTranslations, data.extras);      
    }

    let newExtraTranslations = {};
    for (const extra of extras) {
      const newExtraTranslation = {
        description: "",
        extraCategories: [],
      };

      for (const extraCat of extra.extraCategories) {
        const extraCatTranslation = {
          name: "",
          description: "",
          extraItems: [],
        }

        for (const extraItem of extraCat.extraItems) { 
          const extraItemTranslation = {
            name: "",
          }
          extraCatTranslation.extraItems.push(extraItemTranslation);
        }

        newExtraTranslation.extraCategories.push(extraCatTranslation);
      }
      
      newExtraTranslations[extra.id] = newExtraTranslation;
    }

    for (const key of Object.keys(newExtraTranslations)) {
      if (existingExtraTranslations[key] !== undefined) {
        newExtraTranslations[key] = existingExtraTranslations[key];
      }
    }

    if (extraTranslationsCollection.empty) {
      languageRef.collection('extras').add({
        extras: newExtraTranslations,
      })
    } else {
      languageRef.collection('extras').doc(extraTranslationsCollection.docs[0].id).update({
        extras: newExtraTranslations,
      })
    }
  }

  /**
   * Downloads the menuTranslation with [languageCode] as .json file
   * @param languageCode 
   * @returns 
   */
  public exportJson(languageCode: string) {
    const menuTranslation = this.menuTranslations.get(languageCode);
    if (menuTranslation === undefined) {
      return;
    }

    const content = {
      products: menuTranslation.products,
      categories: menuTranslation.categories,
      extras: menuTranslation.extras,
    };

    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(content, null, 4)], { type: 'text/plain'});
    a.href = URL.createObjectURL(file);
    a.download = `menu-${languageCode}.json`
    a.click();
  }

  public importJson(languageCode: string, jsonFile: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (file: any) => {
        const obj = JSON.parse(file.currentTarget.result)
  
        if (obj.products === undefined || obj.categories === undefined) {
          this.utilService.failedAlert("Datei konnte nicht erfolgreich importiert werden.")
          reject()
        } 
        
        const menuTranslation = this.menuTranslations.get(languageCode)

        for (const key of Object.keys(obj.products)) {
          if (menuTranslation.products[key] !== undefined) {
            menuTranslation.products[key] = obj.products[key];
          }
        }
        
        for (const key of Object.keys(obj.categories)) {
          if (menuTranslation.categories[key] !== undefined) {
            menuTranslation.categories[key] = obj.categories[key];
          }
        }

        for (const key of Object.keys(obj.extras)) {
          const extraTranslation = menuTranslation.extras[key]
          const objExtra = obj.extras[key];
          if (extraTranslation !== undefined) {
            extraTranslation.description = objExtra.description;

            for (const [categoryIndex, objCategory] of objExtra.extraCategories.entries()) {
              const extraCategory = extraTranslation.extraCategories[categoryIndex];
              if (extraCategory !== undefined) {
                extraCategory.name = objCategory.name;
                extraCategory.description = objCategory.description;

                for (const [itemIndex, objItem] of objCategory.extraItems.entries()) {
                  const extraItem = extraCategory.extraItems[itemIndex];
                  if (extraItem !== undefined) {
                    extraItem.name = objItem.name;
                  }
                }
              }
            }
          }
        }
        
        this.saveLanguage(languageCode);
        this.utilService.successAlert("Datei wurde erfolgreich importiert.")
        resolve(true);
      }
  
      reader.readAsText(jsonFile);
    })
  }

  public async exportDefaultJson(products: Product[], categories: Category[], extras: Extra[]) {
    const content = {
      products: {},
      categories: {},
      extras: {},
    }
    for (const product of products) {
      content.products[product.id] = {
        name: product.name !== undefined ? product.name : "",
        description: product.description !== undefined ? product.description : "",
      }
    }
    for (const category of categories) {
      content.categories[category.id] = {
        name: category.name !== undefined ? category.name : "",
        description: category.description !== undefined ? category.description : "",
      }
    }

    for (const extra of extras) {
      const newExtraTranslation = {
        description: "",
        extraCategories: [],
      };

      for (const extraCat of extra.extraCategories) {
        const extraCatTranslation = {
          name: "",
          description: "",
          extraItems: [],
        }

        for (const extraItem of extraCat.extraItems) { 
          const extraItemTranslation = {
            name: "",
          }
          extraCatTranslation.extraItems.push(extraItemTranslation);
        }

        newExtraTranslation.extraCategories.push(extraCatTranslation);
      }
      
      content.extras[extra.id] = newExtraTranslation;
    }

    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(content, null, 4)], { type: 'text/plain'});
    a.href = URL.createObjectURL(file);
    a.download = `menu.json`
    a.click();
  }

  public saveAllLanguages() {
    for (const languageCode of this.getCurrentLanguages()) {
      this.saveLanguage(languageCode);
    }
  }

  /**
   * Saves the menuTranslation of [languageCode] to the database
   */
  public saveLanguage(languageCode: string) {
    const menuTranslation = this.menuTranslations.get(languageCode);
    if (menuTranslation === undefined) {
      return;
    }
    
    const languageRef = this.afs.collection('gastro').doc(this.currentGastroId).collection('languages').doc(menuTranslation.baseDocId);
    
    languageRef.collection('categories').doc(menuTranslation.categoryDocId).set({
      categories: menuTranslation.categories
    });

    languageRef.collection('products').doc(menuTranslation.productDocId).set({
      products: menuTranslation.products
    });

    languageRef.collection('extras').doc(menuTranslation.extrasDocId).set({
      extras: menuTranslation.extras
    })
  }

  /**
   * Deletes all language files in a gastro with languageCode 
   * @param gastroId 
   * @param languageCode 
   */
  public async deleteLanguage(gastroId: string, languageCode: string) {
    const menuTranslation = this.menuTranslations.get(languageCode);
    if (menuTranslation === undefined) {
      return;
    }

    const gastroRef = this.afs.collection('gastro').doc(gastroId);
    gastroRef.collection('languages').doc(menuTranslation.baseDocId).delete();
  }

}
