import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-pop-over',
  templateUrl: './info-pop-over.component.html',
  styleUrls: ['./info-pop-over.component.scss'],
})
export class InfoPopOverComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
