import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuService } from '../services/menu.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UnsafedChangesGuard implements CanDeactivate<unknown> {

  constructor(
    private menuServive: MenuService,
    private alertController: AlertController,
  ) {

  }
  
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return new Promise(async resolve => {
      if (this.menuServive.stagedChanges == true) {
        const alert = await this.alertController.create({
          header: 'Ungesicherte Änderungen',
          message: 'Es gibt noch nicht gespeicherte Änderungen! Beim Verlassen der Seite gehen diese möglicherweise verloren.',
          buttons: [
            {
              text: 'Abbrechen',
              role: 'cancel',
              handler: () => {
                resolve(false);
              }
            }, {
              text: 'Okay',
              handler: () => {
                this.menuServive.stagedChanges = false;
                resolve(true);
              }
            }
          ]
        });
    
        await alert.present();
      } else {
        resolve(true);
      }
    })
  }
}
