import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NegateUserStateGuard implements CanActivate {
    constructor(private router: Router, private afs: AngularFirestore, private afa: AngularFireAuth) {}
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
          return new Promise((resolve, reject) => {
              this.afa.onAuthStateChanged((user: firebase.default.User) => {
                if (user) {
                    this.afs.collection('Gastro-Profiles').doc(user.uid).get().subscribe((doc) => {
						if (doc.exists === false) {
							this.afa.signOut();
							this.router.navigate(['/login']);
							resolve(false);
						}
                        if (doc.data()['profileState'] == 2) {
                          resolve(true);
                        } else {
                            if(doc.data()['profileState'] == 0) {
                                this.router.navigate(['/onboarding']);
                                resolve(false)
                            } else if (doc.data()['profileState'] == 1) {
                                this.router.navigate(['/welcome']);
                                resolve(false)
                            }
                        }
                    })
                } else {
                  console.log('User is not logged in');
                  this.router.navigate(['/login']);
                  resolve(false);
                }
              });
            });
    }
  
}
