import { Injectable } from '@angular/core';
// import { HelloTessServiceService } from './hello-tess-service.service';
import { LightspeedService } from './lightspeed.service';
// import {WooCommerceService} from './wooCommerce.service';
import { Order, PaymentMethod } from '../util.service';
import { Category, Extra, Product } from 'src/app/shared/split-submodules/types/types';

export interface CheckoutSystem {
  /**
   * Async function to determine whether this api is active. 
   * When it is actice it is assured that t has been initialized and that all other methods of the interface CheckoutSystem return valid values
   */
  isActiveAndEnabled(): Promise<boolean>;

  /**
   * Returns all Categories of the api in split-format
   */
  getCategories(): Category[];

  /**
   * Returns all Products of the api in split-format
   */
  getProducts(): Product[];

  /**
   * Returns an Extra in the split format to a given extraId in the split-format
   * @param extraId the extraId which is saved in the product
   */
  getExtra(extraId: string): Extra;

  /**
   * Returns the paymentmethods which are supported by the api
   */
  getPaymentMethods(): PaymentMethod[];

  /**
   * Takes a cart and sends an order to the checkout-system.
   * @param split_cart the cart in split-format
   */
  sendOrder(split_cart: Order);

  /**
   * Returns the name of the api.
   */
  getCheckoutSystemName(): string;
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutSystemApiService {

  private apis: CheckoutSystem[] = [];

  constructor(
    lightspeedService: LightspeedService,
    // helloTessService: HelloTessServiceService,
    // woocommerceService: WooCommerceService
  ) { 
    this.apis.push(lightspeedService);
    // this.apis.push(helloTessService);
    // this.apis.push(woocommerceService)
  }

  /**
   * Checks if there are any apis which holds products, categories or paymentmethods and returns the first active api
   * @returns a Promise to an active api or undefined if none was found
   */
  public getActiveApi(): Promise<CheckoutSystem> {
    return new Promise<CheckoutSystem>(
      async (resolve, reject) => {
        for (const api of this.apis) {
          if (await api.isActiveAndEnabled()) {
            resolve(api);
            return;
          } 
        }
        resolve(undefined)
      }
    )
  }
}
