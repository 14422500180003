// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL:"https://gastronom.split-app.dev",
  firebase: {
    apiKey: "AIzaSyDp1IP83ZjhX1c13fUqZda88WYWWZcRg0s",
    authDomain: "split-test-1.firebaseapp.com",
    databaseURL: "https://split-test-1.firebaseio.com",
    projectId: "split-test-1",
    storageBucket: "split-test-1.appspot.com",
    messagingSenderId: "424925011673",
    appId: "1:424925011673:web:dda8c3ee689682fa",
    measurementId: "G-FPS65V51QT",
    toolKitURL: "https://identitytoolkit.googleapis.com"
  },
  functionsUrlEU: "https://europe-west3-split-test-1.cloudfunctions.net/",
  functionsUrl: "https://us-central1-split-test-1.cloudfunctions.net/",
  globalID: "lHWzjXutxy8VvyytHVh9",
  stripePK: "pk_test_yDzAbQwZZIFXwZxacICWYI8S00IN3MtoTA",
  orderbirdAPIKey: "viyv2Ej0TgddZUF2BNsSoRAvOJHZT74mHcTe2yA2s3LkGbbvPk9cV1ozjhWdNbxfzG6U2ZHDsJ9EJXSxcqWyP0ksJJE1yO38ZlqJhEz3j4JbrYAieBZfsV2TEkQ6iUGJ",
  orderbird: {
    url: "http://staging-myob.orderbird.com/",
    clientId: "YH6MWBo36tcBY3biPOKVOZqD1AecE3yAOerGPyly",
  },
  bigQAnalyticsURL: 'split-test-1.analytics_202969576'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
