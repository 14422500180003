import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  AngularFirestore,
  DocumentData,
  QueryDocumentSnapshot,
} from "@angular/fire/compat/firestore";
3;
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { AuthService } from "src/app/services/auth.service";
import { ApplicationRef, Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import * as firebase from "firebase/compat/app";
import { Subscription } from "rxjs";
import { Gastro } from "../shared/split-submodules/types/types";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  public rootUser = '';
  public user: firebase.default.User
  public gastros = [];
  public checkoutSysAPIs = [];
  private apiSubscription
  public selectedGastros = [];
  public selectedGastroInVB: any = { id: '', data: {} };
  public gastroProfile
  public canAccessGodmode: boolean = false
  public godmodeActive: boolean = false

  private accountManagementSubscription: Subscription;
  private gastroSubscriptions: Subscription[] = [];

  public isOrderbirdApiActive: boolean = false;

  private selectedGastroInVbSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private afa: AngularFireAuth,
    public ar: ApplicationRef,
    private fns: AngularFireFunctions,
    private toastController: ToastController
  ) {
    this.afa.onAuthStateChanged(async (u) => {
      if (u) {
        this.rootUser = u.uid;
        this.user = u

        if (this.selectedGastroInVbSubscription !== undefined) {
          this.selectedGastroInVbSubscription.unsubscribe();
        }

        this.selectedGastroInVB.id = u.uid
        this.selectedGastroInVbSubscription = this.afs
              .collection('gastro')
              .doc(u.uid)
              .valueChanges()
              .subscribe((doc: Gastro) => {
                  this.selectedGastroInVB.data = doc;
              });
        if (this.accountManagementSubscription !== undefined) {
          this.accountManagementSubscription.unsubscribe();
        }

        this.accountManagementSubscription = this.afs
          .collection('accountManagement', (ref) =>
            ref
              .where('rootUsers', 'array-contains', this.rootUser)
              .limit(1)
          )
          .valueChanges()
          .subscribe((doc: any) => {
            if (doc[0] != undefined) {
              this.gastros = [];
              this.checkoutSysAPIs = [];
              this.isOrderbirdApiActive = false;
              for (const sub of this.gastroSubscriptions) {
                sub.unsubscribe();
              }
              this.gastroSubscriptions = [];
              doc[0].gastros.forEach(async (gastro) => {
                //subscription for API Checkout Systems
                let docs = await this.afs
                .collection('gastro')
                .doc(gastro)
                .collection('checkoutSystemApi')
                .get().toPromise()
                docs.forEach((doc)=>{
                  const id = doc.id;
                  const data = doc.data();
                  this.checkoutSysAPIs.push({id: id,data:data})

                  if (id === 'orderbird') {
                    this.isOrderbirdApiActive = true;
                  }
                })
                
                const subscription = this.afs
                  .collection('gastro')
                  .doc(gastro)
                  .valueChanges()
                  .subscribe((doc) => {
                    let gastroDoc = {
                      id: gastro,
                      data: doc,
                    };
                    (<any>gastroDoc.data).color = "#" + (<any>gastroDoc.data).color
                    let foundGastrosIndex = this.gastros.findIndex(
                      (elem) => elem.id == gastro
                    );
                    if (foundGastrosIndex == -1) {
                      this.gastros.push(<any>gastroDoc);
                    } else {
                      this.gastros[
                        foundGastrosIndex
                      ].data = <any>doc;
                    }

                    let foundSelectedGastrosIndex = this.selectedGastros.findIndex(
                      (elem) => elem.id == gastro
                    );
                    if (foundSelectedGastrosIndex == -1) {
                      this.selectedGastros.push(
                        gastroDoc
                      );
                    } else {
                      this.selectedGastros[
                        foundSelectedGastrosIndex
                      ].data = doc;
                    }
                    this.ar.tick();
                  });
                this.gastroSubscriptions.push(subscription);
              });
            }
          });
        this.gastroProfile = await this.getGastroProfileDoc()
        this.canAccessGodmode = await this.canAccessGodmodeHelper()
      } else {
        this.checkoutSysAPIs = [];
        this.isOrderbirdApiActive = false;

        if (this.selectedGastroInVbSubscription !== undefined) {
          this.selectedGastroInVbSubscription.unsubscribe();
        }
	  }
    });
  }

  async canAccessGodmodeHelper() {
    let docs = await this.afs.collection('dashboard-user', ref => ref.where("email", "==", this.user.email)).get().toPromise()
    if (docs.empty) {
      return false
    } else if (docs.docs[0].data()['role'] === 'ADMIN') {
      return true
    }
    return false
  }

  get loadGastros() {
    return this.afs
      .collection('accountManagement', (ref) =>
        ref.where('rootUsers', 'array-contains', this.rootUser).limit(1)
      )
      .valueChanges();
  }

  toggleGastro(gastroId) {
    let index = this.selectedGastros.findIndex((elem) => elem.id == gastroId);
    if (index != -1) {
      this.selectedGastros.splice(index, 1);
    } else {
      let j = this.gastros.findIndex((elem) => elem.id == gastroId);
      this.selectedGastros.push(this.gastros[j]);
    }
  }

  selectNone() {
    this.selectedGastros = [];
  }

  selectAll() {
    this.selectedGastros = [];
    this.gastros.forEach((gastro) => {
      this.selectedGastros.push(gastro);
    });
  }

  saveSelectedGastro() {
    this.afs
      .collection("gastro")
      .doc(this.selectedGastroInVB.id)
      .update(this.selectedGastroInVB.data);
  }

  /**
   * remove the hex sign for the db of a given gastro
   * @param gastro gastro object
   */
  removeHexSign(gastro) {
    gastro.data.color = gastro.data.color?.replace("#", "");
  }

  /**
   * add the hex sign again for frontend reasons
   * @param gastro gastro object
   */
  addHexSign(gastro) {
    if (gastro.data.color.includes("#") === false) {
      gastro.data.color = "#" + gastro.data.color;
    }
  }

  saveGastroProfil(onboarding?: boolean) {
    this.gastros.forEach((gastro) => {
      this.removeHexSign(gastro)
      this.afs
        .collection('gastro')
        .doc(gastro.data.id)
        .update(gastro.data).then(() => this.addHexSign(gastro));
    });
    this.successAlert('Änderungen wurden erfolgreich gesichert.');
  }
  private async successAlert(message: string) {
    const toast = await this.toastController.create({
      message: 'Änderungen wurden erfolgreich gesichert.',
      duration: 3000,
      position: 'bottom',
      color: 'success',
    });
    toast.present();
  }

  getGastroFromId(id) {
    let i = this.gastros.findIndex((elem) => elem.id == id);
    if (id == -1) {
      return null;
    } else {
      return this.gastros[i];
    }
  }

  /**
   * determine if the logged in gastronom (gastro-profile) already has a stripe customer
   * @returns Boolean
   */
  async hasStripeCustomer(): Promise<Boolean> {
    let privateDoc: Boolean | QueryDocumentSnapshot<DocumentData> =
      await this.getPrivateDoc();

    if (privateDoc == false) {
      return false;
    }

    if (
      (privateDoc as QueryDocumentSnapshot<DocumentData>).data()
        .stripeCustomer != "" &&
      (privateDoc as QueryDocumentSnapshot<DocumentData>).data()
        .stripeCustomer != undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * determine if the logged in gastronom (gastro-profile) already has a stripe customer
   * @returns Boolean
   */
  async hasStripeCard(): Promise<Boolean> {
    let privateDoc: Boolean | QueryDocumentSnapshot<DocumentData> =
      await this.getPrivateDoc();

    if (privateDoc == false) {
      return false;
    }

    if (
      (privateDoc as QueryDocumentSnapshot<DocumentData>).data()
        .paymentMethod != "" &&
      (privateDoc as QueryDocumentSnapshot<DocumentData>).data()
        .paymentMethod != undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * creats a stripe customer and saves the id in Gastro-Profile/private
   * also checks if there is already a customer in the database
   */
  async createStripeCustomer() {
    //just a secure check if there is already a stripe customer
    let hasStripeCustomer = await this.hasStripeCustomer();
    if (hasStripeCustomer === false) {
      const gastroProfile = await this.getGastroProfileDoc();

      if (gastroProfile != false) {
        let profileRef = gastroProfile as QueryDocumentSnapshot<DocumentData>;
        const mail = profileRef.data().mail;
        const name = "Gastro mit der Mail - " + profileRef.data().mail;
        let createStripeCustomer = firebase.default
          .functions()
          .httpsCallable("createGastroStripeCustomer");
        let result = await createStripeCustomer({
          mail: mail,
          gastroName: name,
        });
        let privateDoc = await this.getPrivateDoc();

        if (privateDoc == false) {
          profileRef.ref
            .collection("private")
            .add({ stripeCustomer: result.data.customer });
        } else {
          (privateDoc as QueryDocumentSnapshot<DocumentData>).ref.update({
            stripeCustomer: result.data.customer,
          });
        }
      }
    }
  }

  /**
   * returns if customer already has paymentMethod in privateDoc
   * @returns Boolean
   */
  async hasPaymentMethod(): Promise<Boolean> {
    const privateDoc = await this.getPrivateDoc();

    if (privateDoc == false) {
      return false;
    }

    const privateDocData = (
      privateDoc as QueryDocumentSnapshot<DocumentData>
    ).data();

    if (
      privateDocData.paymentMethod != "" &&
      privateDocData.paymentMethod != undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * gets private doc of gastro-profile
   * @returns a ref to the private doc and false if empty
   */
  async getPrivateDoc(): Promise<
    QueryDocumentSnapshot<DocumentData> | Boolean
  > {
    let privateDocs = await this.afs
      .collection("Gastro-Profiles")
      .doc(this.rootUser)
      .collection("private")
      .get()
      .toPromise();

    if (privateDocs.empty) {
      return false;
    } else {
      return privateDocs.docs[0];
    }
  }

  /**
   * gets doc of gastro-profile
   * @returns a ref to the gastro-profile doc and false if empty
   */
  async getGastroProfileDoc(): Promise<
    QueryDocumentSnapshot<DocumentData> | Boolean
  > {
    let privateDocs = await this.afs
      .collection("Gastro-Profiles")
      .doc(this.rootUser)
      .get()
      .toPromise();

    if (!privateDocs.exists) {
      return false;
    } else {
      return privateDocs;
    }
  }

  /**
   * This function saves changes on gastro data with merge=true
   * 
   */
  saveChangesWithMerge() {
    this.gastros.forEach((gastro) => {
      this.afs
        .collection('gastro')
        .doc(gastro.data.id)
        .set(gastro.data, { merge: true }).then(() => {
          this.successAlert('Änderungen wurden erfolgreich gesichert.');
        });
    });
  }
}
